@import "../_styles/vars";


.slider--contain {
  max-width: 120%;
  position: relative;
  margin: 0 -32px; }
.cameraList {
  .slider--contain {
    margin: 0; } }

.slider--count-video {
  margin-left: 35px;
  color: var(--color-text-secondary);
  font-size: $fontSizeSmall; }

.slider--row {
  overflow: hidden;
  width: 100%; }

.slider--row-inner {
  transition: 450ms transform;
  white-space: nowrap;
  margin: 13px 0;
  padding-bottom: 10px; }

.slider-nav--next:hover .slider--row-inner {
  transform: translateX(50%); }

.slider--tile {
  position: relative;
  display: inline-block;
  height: 140.625px;
  margin-right: 10px;
  cursor: pointer;
  transition: 450ms all;
  transform-origin: center; }

.slider--tile-img {
  width: 165px;
  height: 140.625px;
  -o-object-fit: cover; }

.slider--row-inner:hover {
  transform: translate3d(-5px, 0, 0); }

.slider--row-inner:hover .slider--tile {
  opacity: 0.3; }

.slider--row-inner:hover .slider--tile:hover {
  transform: scale(1.05);
  opacity: 1; }

.slider--tile:hover ~ .slider--tile {
  transform: translate3d(5px, 0, 0); }

.slider-nav {
  padding: 16px;
  position: absolute;
  z-index: 1;
  height: 100%;
  top: 0;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: .3;
  transition: opacity $time $easeMd;

  &:hover {
    opacity: 1 !important; } }

.slider-nav--prev {
  left: 0; }

.slider-nav--next {
  right: 0; }

.slider-nav-icon {
  width: 12px;
  height: 18px;
  color: black; }

.top-slider-line {
  margin: 16px -32px; }
