.tabs-layout--vertical {
  display: flex;

  .tabs {
    background: var(--color-background-gray200);
    flex-direction: column;
    box-shadow: none; }

  .tab--active {
    box-shadow: none;
    background-color: var(--color-background-card); }

  .tab--hasIcon {
    padding: 15px; }

  .tabs + * {
    flex: 1 auto; } }

.tabs--noTabLabel {
  .tab-icon {
    margin-bottom: 0; } }

.theme--dark {
  .tabs-layout--vertical {
    .tabs {
      background: rgba(255,255,255,0.03); } } }
